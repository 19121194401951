import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

var theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#7997d2',
      main: '#203560',
      dark: '#203560',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#406ABF',
      main: '#406ABF',
      dark: '#406ABF',
      contrastText: '#FFF',
      contrastText: '#FFF',
    },
    text: {
      primary: '#203560',
      secondary: '#203560',
    },
    background: {
      paper: '#FFF',
    },
  },
  typography: {
    h1: {
      fontFamily: `"Roboto Slab", serif`,
      fontWeight: 400,
    },
    h2: {
      fontFamily: `"Roboto Slab", serif`,
      fontWeight: 700,
    },
    h3: {
      fontFamily: `"Roboto Slab", serif`,
      fontWeight: 500,
    },
    h4: {
      fontFamily: `"Roboto Slab", serif`,
      fontWeight: 400,
    },
    h5: {
      fontFamily: `"Roboto Slab", serif`,
      fontWeight: 400,
    },
    h6: {
      fontFamily: `"Roboto Slab", serif`,
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
    },
    body1: {
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 400,
    },
    body2: {
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 400,
    },
    button: {
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 700,
    },
    caption: {
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 400,
    },
    overline: {
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 700,
    },
  },
})

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
