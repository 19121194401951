import React from 'react'
import Theme from './src/providers/theme'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

export const wrapRootElement = ({ element }) => {
  return (
    <Theme>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {element}
      </MuiPickersUtilsProvider>
    </Theme>
  )
}
